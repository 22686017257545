import './App.css';
import DocumentViewer from './Document';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
  return (
    <Router>
    <Routes >
      <Route path="/preview" exact element={<DocumentViewer/>} />
    </Routes>
  </Router>
  );
}

export default App;
